import { useTranslation } from "react-i18next"


export default function useOrderDataTaskUtil() {
    const { t } = useTranslation();

    const returnNormCountOfItems = (orderDataArray) => {
        let countData = []
        let allMenuItems = []
        let allMenuItemsStockItems = []
        if (orderDataArray?.length > 0) {
            orderDataArray.forEach(element => {
                allMenuItems = [...allMenuItems, ...element.menuItems]
            });
            if (allMenuItems.length > 0) {
                allMenuItems.forEach(element => {
                    if (element?.menuItemStockItemDTO) {
                        allMenuItemsStockItems = [...allMenuItemsStockItems, ...element.menuItemStockItemDTO]
                    }

                });
                if (allMenuItemsStockItems.length > 0) {
                    allMenuItemsStockItems.forEach(element => {
                        let elementData = {}
                        let indexOfElement = countData?.findIndex(x => element?.stockItemDTO?.id == x.id)
                        if (indexOfElement != -1) {
                            elementData = { ...countData[indexOfElement], amount: countData[indexOfElement].amount + element.amount }
                            countData[indexOfElement] = elementData
                        } else {
                            elementData = {
                                id: element?.stockItemDTO?.id,
                                name: element?.stockItemDTO?.name,
                                unit: t(element?.stockItemDTO?.unit),
                                amount: element.amount
                            }
                            countData.push(elementData)

                        }
                    });
                }
            }

        }
        return [...countData]

    }

    return {
        returnNormCountOfItems: returnNormCountOfItems
    }
};



