import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerService from '../../service/CustomerService';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
const CustomerConfiguration = (props) => {
    const { customerId } = props
    const [showGenerate, setShowGenerate] = useState(false)
    const [isDailyBased, setIsDailyBased] = useState(false)
    const { t } = useTranslation();
    const toast = useRef(null);
    const customerService = new CustomerService();
    useEffect(() => {
        customerService.getCustomerCateringConfiguration(customerId).then((data) => {
            if (data?.customerCateringId) {
                setValue('customerCateringId', data?.customerCateringId)
            }
            if (data?.numberOfShifts) {
                setValue('numberOfShifts', data?.numberOfShifts)
            }
            if (data?.numberOfDaysBeforeMenuIsActive) {
                setValue('numberOfDaysBeforeMenuIsActive', data?.numberOfDaysBeforeMenuIsActive)
            }
            if (data?.numberOfDaysBeforeMenuIsNotActive) {
                setValue('numberOfDaysBeforeMenuIsNotActive', data?.numberOfDaysBeforeMenuIsNotActive)
            }
            if (data?.numberOfDaysBeforeMenuIsActiveTime) {
                setValue('numberOfDaysBeforeMenuIsActiveTime', data?.numberOfDaysBeforeMenuIsActiveTime)
            }
            if (data?.numberOfDaysBeforeMenuIsNotActiveTime) {
                setValue('numberOfDaysBeforeMenuIsNotActiveTime', data?.numberOfDaysBeforeMenuIsNotActiveTime)
            }
            if (data?.dailyBasedOrder) {
                setValue('dailyBasedOrder', data?.dailyBasedOrder)
                setIsDailyBased(true)
            }
            if (data?.allowPricing) {
                setValue('allowPricing', data?.allowPricing)
            }
            if (data?.cartType) {
                setValue('cartType', data?.cartType)
            }
            if (data?.publicIntegrationKey) {
                setShowGenerate(false)
                setValue('publicIntegrationKey', data?.publicIntegrationKey)
            } else {
                setShowGenerate(true)
            }
        })
    }, [])

    let defaultValues = {
        customerCateringId: 0,
        numberOfShifts: 1,
        numberOfDaysBeforeMenuIsActive: 7,
        numberOfDaysBeforeMenuIsNotActive: 2,
        numberOfDaysBeforeMenuIsActiveTime: "10:00",
        numberOfDaysBeforeMenuIsNotActiveTime: "13:00",
        publicIntegrationKey: '',
        dailyBasedOrder: false,
        allowPricing: false,
        cartType: false,

    }

    const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm({ defaultValues, mode: 'all' });
    const submitFunction = (data) => {
        customerService.saveCustomerCateringConfiguration(data).then(() => {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerSettingsUpdated'), life: 3000 })
        }).catch(reason => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorCustomerSettingsUpdated'), life: 3000 })
        })

    }
    const generatePublicKey = () => {
        customerService.generatePublicKey(getValues().customerCateringId).then(data => {
            setValue('publicIntegrationKey', data)
            setShowGenerate(false)

        })
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const onDailyBasedChange = (value) => {
        setIsDailyBased(value)
    }
    const numberOfShiftsOptions = [
        {
            label: "1",
            value: 1,
        },
        {
            label: "2",
            value: 2,
        },
        {
            label: "3",
            value: 3,
        },
    ]
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("save")} icon="pi pi-save" className="p-button-success mr-2" />
                </div>
            </React.Fragment>
        )
    }
    function copyToClipboard() {
        // For mobile devices
        // Copy the text inside the text field
        navigator.clipboard.writeText(getValues().publicIntegrationKey);
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">

                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                        <div className='col-12 mt-3'>
                            <h4>{t('settings')}</h4>
                        </div>
                        <div className='col-12 grid'>
                            <div className='col-12 grid'>
                                <div className="field col-12 md:col-4 lg:col-4">
                                    <label htmlFor="numberOfShifts" className={classNames({ 'p-error': errors.numberOfShifts })}>{t('numberOfShifts')}*</label>
                                    <Controller name="numberOfShifts" control={control} rules={{ required: t('numberOfShiftsRequired') }} render={({ field, fieldState }) => (
                                        <Dropdown id={field.name} options={numberOfShiftsOptions} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {getFormErrorMessage('numberOfShifts')}
                                </div>
                                <div className="field">
                                    <label htmlFor="dailyBasedOrder">{t('dailyBasedOrder')}</label>
                                    <Controller name="dailyBasedOrder" control={control} render={({ field, fieldState }) => (
                                        <Checkbox inputId={field.name} onChange={(e) => {
                                            field.onChange(e.checked)
                                            onDailyBasedChange(e.checked)
                                        }} checked={field.value} />
                                        // <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                </div>
                                <div className="field ml-3">
                                    <label htmlFor="cartType">{t('cartType')}</label>
                                    <Controller name="cartType" control={control} render={({ field, fieldState }) => (
                                        <Checkbox inputId={field.name} onChange={(e) => {
                                            field.onChange(e.checked)
                                        }} checked={field.value} />
                                    )} />
                                </div>
                                <div className="field ml-3">
                                    <label htmlFor="allowPricing">{t('allowPricing')}</label>
                                    <Controller name="allowPricing" control={control} render={({ field, fieldState }) => (
                                        <Checkbox inputId={field.name} onChange={(e) => {
                                            field.onChange(e.checked)
                                        }} checked={field.value} />
                                    )} />
                                </div>
                            </div>
                            <div className='col-12 md:col-8 lg:col-8 grid'>
                                <div className="field col-12 lg:col-6">
                                    <label htmlFor="numberOfDaysBeforeMenuIsActive" className={classNames({ 'p-error': errors.numberOfDaysBeforeMenuIsActive })}>{t('numberOfDaysBeforeMenuIsActive')}*</label>
                                    <Controller name="numberOfDaysBeforeMenuIsActive" control={control} rules={{ required: t('numberOfDaysBeforeMenuIsActiveRequired') }} render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {getFormErrorMessage('numberOfDaysBeforeMenuIsActive')}
                                </div>
                                <div className="field col-12 lg:col-6">
                                    <label htmlFor="numberOfDaysBeforeMenuIsActiveTime" className={classNames({ 'p-error': errors.numberOfDaysBeforeMenuIsActiveTime })}>{t('numberOfDaysBeforeMenuIsActiveTime')}*</label>
                                    <Controller name="numberOfDaysBeforeMenuIsActiveTime" control={control} rules={{
                                        required: t('timeRequired'), pattern: {
                                            value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                                            message: t("invalidTimeFormat")
                                        }
                                    }} render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {getFormErrorMessage('numberOfDaysBeforeMenuIsActiveTime')}
                                </div>
                            </div>
                            <div className='col-12 md:col-8 lg:col-8 grid'>
                                {!isDailyBased && <div className="field col-12 lg:col-6">
                                    <label htmlFor="numberOfDaysBeforeMenuIsNotActive" className={classNames({ 'p-error': errors.numberOfDaysBeforeMenuIsNotActive })}>{t('numberOfDaysBeforeMenuIsNotActive')}*</label>
                                    <Controller name="numberOfDaysBeforeMenuIsNotActive" control={control} rules={{ required: t('numberOfDaysBeforeMenuIsNotActiveRequired') }} render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {getFormErrorMessage('numberOfDaysBeforeMenuIsNotActive')}
                                </div>}
                                <div className="field col-12 lg:col-6">
                                    <label htmlFor="numberOfDaysBeforeMenuIsNotActiveTime" className={classNames({ 'p-error': errors.numberOfDaysBeforeMenuIsNotActive })}>{t('numberOfDaysBeforeMenuIsNotActiveTime')}*</label>
                                    <Controller name="numberOfDaysBeforeMenuIsNotActiveTime" control={control} rules={{
                                        required: t('timeRequired'), pattern: {
                                            value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                                            message: t("invalidTimeFormat")
                                        }
                                    }} render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    {getFormErrorMessage('numberOfDaysBeforeMenuIsNotActiveTime')}
                                </div>
                            </div>
                            {/* <div className="field col-12 lg:col-4">
                                <label htmlFor="publicIntegrationKey" className={classNames({ 'p-error': errors.publicIntegrationKey })}>{t('publicIntegrationKey')}</label>
                                <Controller name="publicIntegrationKey" control={control} rules={{ required: t('publicIntegrationKeyRequired') }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} disabled className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('publicIntegrationKey')}
                                <Button type="button" label={showGenerate ? t('generate') : t('copy')} icon={showGenerate ? "pi pi-sync" : "pi pi-copy"} className="col-6 lg:w-10rem mt-2" onClick={showGenerate ? () => generatePublicKey() : () => copyToClipboard()} />
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>

        </div >
    )
}


export default CustomerConfiguration