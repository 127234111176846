import moment from 'moment/moment';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ContextMenu } from 'primereact/contextmenu';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import ItemCategoryService from '../../service/ItemCategoryService';
import MenuItemService from '../../service/MenuItemService';
import MenuService from '../../service/MenuService';
import { searchCustomerParams, searchItemCategoryParams, searchMenuItemParams, searchMenuParams } from '../../service/serviceObjects';
import { isMobile, sortArray } from '../../utilities/general';
import { groupMenuItemsByCategoryId, returnMenuForSpecificDate } from '../../utilities/menuItemUtil';
import { isDateInEditRange } from '../../utilities/timeUtil';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Controller, useForm } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { SelectButton } from 'primereact/selectbutton';
const MenuClient = () => {
    const toast = useRef(null);
    const op = useRef(null);
    const cm = useRef(null);
    const menuService = new MenuService();
    const itemCategoryService = new ItemCategoryService();
    const customersService = new CustomerService();
    const menuItemService = new MenuItemService();
    const [firstDayOfWeek, setFirstDayOfWeek] = useState();
    const [lastDayOfWeek, setLastDayOfWeek] = useState();
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [originalMenusForCurrentWeek, setOriginalMenusForCurrentWeek] = useState();
    const [menusForCurrentWeek, setMenusForCurrentWeek] = useState([]);
    const [defaultMenuForCustomer, setDefaultMenuForCustomer] = useState();
    const [templateMenus, setTemplateMenus] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [itemCategories, setItemCategories] = useState([]);
    const [customer, setCustomer] = useState();
    const [menuType, setMenuType] = useState('multiDay');
    const [menuDay, setMenuDay] = useState(new Date());
    const [customersDropdownList, setCustomersDropdownList] = useState([]);
    const [menuItemsForSelectedCategory, setMenuItemsForSelectedCategory] = useState([]);
    const [originalMenuItemsForSelectedCategory, setOriginalMenuItemsForSelectedCategory] = useState([]);
    const [selectedMenuItemsForSelectedCategory, setSelectedMenuItemsForSelectedCategory] = useState([]);
    const [currentSelectedDate, setCurrentSelectedDate] = useState();
    const [customerConfiguration, setCustomerConfiguration] = useState();
    const [showMenuPreviewDialog, setShowMenuPreviewDialog] = useState(false);
    const [layout, setLayout] = useState('grid');

    let defaultValues = {
        date: '',
        dateActiveFrom: '',
        dateActiveTo: '',
        template: '',
        templateName: '',

    }
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const submitFunction = (data) => {
        changeValidityDate(data.date, data)
        reset()
        op.current.hide()
    }
    const onSettingsClick = (event, date) => {
        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        if (singleElement) {
            setValue('date', date)
            setValue('dateActiveFrom', new Date(singleElement.dateActiveFrom))
            setValue('dateActiveTo', new Date(singleElement.dateActiveTo))
            setValue('template', singleElement.template ?? false)
            setValue('templateName', singleElement.name ?? false)
            op.current.toggle(event)
        }
    }
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    let menuTypeOptions = [
        { label: (t('multiDay')), value: 'multiDay' },
        { label: (t('singleDay')), value: 'singleDay' },
        { label: (t('defaultMenu')), value: 'defaultMenu' },
    ];
    if (isMobile()) {
        menuTypeOptions = menuTypeOptions.filter(x => x.value != 'singleDay')
    }
    const saveChanges = (date) => {
        setShowMenuPreviewDialog()
        saveMenu()
    }
    const saveMenu = (date) => {
        // let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        setShowProgressBar(true)
        menuService.batchSave(menusForCurrentWeek).then((value) => {
            getActiveMenus(firstDayOfWeek, lastDayOfWeek)
            getTemplateMenu(customer)
            setShowProgressBar(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuSaved'), life: 3000 })
        }).catch(reason => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSaveMenu'), life: 3000 })
        })

    }
    const saveDefaultMenu = (date) => {
        // let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        setShowProgressBar(true)
        menuService.save(defaultMenuForCustomer).then((value) => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuSaved'), life: 3000 })
        }).catch(reason => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSaveMenu'), life: 3000 })
        })

    }
    const changeValidityDate = (date, newValues) => {
        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        let existingActiveMenus = [...menusForCurrentWeek]
        if (singleElement) {
            let indexOfActiveMenu = menusForCurrentWeek?.findIndex(x => x.menuDate === moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString())
            if (indexOfActiveMenu !== -1) {
                let menuToChange = { ...existingActiveMenus[indexOfActiveMenu], dateActiveFrom: moment(newValues.dateActiveFrom).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(), dateActiveTo: moment(newValues.dateActiveTo).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(), template: newValues.template, name: newValues.templateName }
                existingActiveMenus[indexOfActiveMenu] = menuToChange
            }
            setMenusForCurrentWeek(existingActiveMenus)
        }

    }
    const applyTemplateToDate = (date, menu) => {
        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        let existingActiveMenus = [...menusForCurrentWeek]
        if (singleElement) {
            let indexOfActiveMenu = menusForCurrentWeek?.findIndex(x => x.menuDate === moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString())
            if (indexOfActiveMenu !== -1) {
                existingActiveMenus[indexOfActiveMenu] = menu
            }
            setMenusForCurrentWeek(existingActiveMenus)
        } else {
            let newMenu = { ...menu, menuDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString() }
            existingActiveMenus.push(newMenu)
            setMenusForCurrentWeek(existingActiveMenus)
        }


    }
    let contextMenuItems = [
        {
            label: (t('copy')),
            icon: 'pi pi-fw pi-copy',
            disabled: !menusForCurrentWeek?.length > 0,
            command: () => copyMenu()
        },
        {
            label: (t('paste')),
            icon: 'pi pi-fw pi-file',
            disabled: !localStorage.getItem('copiedMenu') || localStorage.getItem('copiedFirstDay') != firstDayOfWeek,
            command: () => pasteMenu()
        }
    ]
    const onDialogMenuItemChange = (e, menuItem) => {
        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, currentSelectedDate)
        let dateActiveFromTime = customerConfiguration.numberOfDaysBeforeMenuIsActiveTime ? "T" + customerConfiguration.numberOfDaysBeforeMenuIsActiveTime + ":00.00" : "T10:00:00.00"
        let dateActiveToTime = customerConfiguration.numberOfDaysBeforeMenuIsNotActiveTime ? "T" + customerConfiguration.numberOfDaysBeforeMenuIsNotActiveTime + ":00.00" : "T13:00:00.00"
        let dateActiveFrom = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(customerConfiguration.numberOfDaysBeforeMenuIsActive ?? 7, 'day').format("YYYY-MM-DD").toString() + dateActiveFromTime
        let dateActiveTo = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(customerConfiguration.numberOfDaysBeforeMenuIsNotActive ?? 2, 'day').format("YYYY-MM-DD").toString() + dateActiveToTime
        if (customerConfiguration.dailyBasedOrder) {
            dateActiveTo = moment(currentSelectedDate, "DD/MM/YYYY").subtract(1, 'day').format("YYYY-MM-DD").toString() + dateActiveToTime
        }
        let newActiveMenu = {
            customerId: customer,
            menuDate: moment(currentSelectedDate, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            dateActiveFrom: dateActiveFrom,
            dateActiveTo: dateActiveTo,
            name: "Menu - " + moment(new Date(), "DD/MM/YYYY").toString(),
            cateringId: authContext.cateringId || 0,
            menuItemDTOList: []
        }
        let existingActiveMenus = [...menusForCurrentWeek]

        if (!singleElement) {
            newActiveMenu.menuItemDTOList = [menuItem]
            existingActiveMenus.push(newActiveMenu)
            setMenusForCurrentWeek(existingActiveMenus)
        } else {
            let indexOfActiveMenu = menusForCurrentWeek?.findIndex(x => x.menuDate === moment(currentSelectedDate, "DD/MM/YYYY").format("YYYY-MM-DD").toString())
            if (indexOfActiveMenu !== -1) {
                let newMenuItems = []
                if (e.checked) {
                    newMenuItems = [...existingActiveMenus[indexOfActiveMenu].menuItemDTOList, menuItem]
                } else {
                    newMenuItems = existingActiveMenus[indexOfActiveMenu].menuItemDTOList.filter(x => x.id !== e.value)
                }
                if (newMenuItems.length > 0) {
                    existingActiveMenus[indexOfActiveMenu] = {
                        ...existingActiveMenus[indexOfActiveMenu],
                        menuItemDTOList: newMenuItems
                    }
                } else {
                    existingActiveMenus.splice(indexOfActiveMenu, 1)
                }

            }
            setMenusForCurrentWeek(existingActiveMenus)

        }

        let selectedmenuItemsForSelectedCategoryList = [...selectedMenuItemsForSelectedCategory];
        if (e.checked)
            selectedmenuItemsForSelectedCategoryList.push(e.value);
        else
            selectedmenuItemsForSelectedCategoryList.splice(selectedmenuItemsForSelectedCategoryList.indexOf(e.value), 1);
        setSelectedMenuItemsForSelectedCategory(selectedmenuItemsForSelectedCategoryList);
    }
    const onDialogDefaultMenuItemChange = (e, menuItem) => {

        let singleElement = defaultMenuForCustomer
        let newActiveMenu = {
            customerId: customer,
            menuDate: "2030-10-10",
            dateActiveFrom: "1900-10-10T10:00:00.00",
            dateActiveTo: "2100-10-10T10:00:00.00",
            name: "Default menu",
            cateringId: authContext.cateringId || 0,
            default: true,
            menuItemDTOList: []
        }

        if (!singleElement) {
            newActiveMenu.menuItemDTOList = [menuItem]
            setDefaultMenuForCustomer(newActiveMenu)
        } else {
            let newMenuItems = []
            if (e.checked) {
                newMenuItems = [...singleElement.menuItemDTOList, menuItem]
            } else {
                newMenuItems = singleElement.menuItemDTOList.filter(x => x.id !== e.value)
            }
            if (newMenuItems.length > 0) {
                singleElement = {
                    ...singleElement,
                    menuItemDTOList: newMenuItems
                }
                setDefaultMenuForCustomer(singleElement)
            }

        }

        let selectedmenuItemsForSelectedCategoryList = [...selectedMenuItemsForSelectedCategory];
        if (e.checked)
            selectedmenuItemsForSelectedCategoryList.push(e.value);
        else
            selectedmenuItemsForSelectedCategoryList.splice(selectedmenuItemsForSelectedCategoryList.indexOf(e.value), 1);
        setSelectedMenuItemsForSelectedCategory(selectedmenuItemsForSelectedCategoryList);
    }
    const onCustomerChange = (value) => {
        // getTemplateMenu(value)
        setCustomer(value)
    }
    const getAllMenuItemsForCategory = (categoryId) => {
        setShowProgressBar(true)
        let searchObject = { ...searchMenuItemParams }
        searchObject.cateringId = authContext.cateringId;
        searchObject.pageSize = 1000
        searchObject.itemCategoryId = categoryId
        menuItemService.search(searchObject).then(data => {
            setShowProgressBar(false)
            setMenuItemsForSelectedCategory(data.items)
            setOriginalMenuItemsForSelectedCategory(data.items)
            setShowDialog(true)
        })

    }
    const addNewItemForCategory = (categoryId, currentDate, alreadySelectedMenuItems) => {
        if (alreadySelectedMenuItems?.[0]?.elements) {
            let alreadySelectedMenuItemsIds = alreadySelectedMenuItems?.[0]?.elements.map(x => x.id)
            setSelectedMenuItemsForSelectedCategory(alreadySelectedMenuItemsIds)
        }
        getAllMenuItemsForCategory(categoryId)
        setCurrentSelectedDate(currentDate)
    }
    const getMyCustomers = () => {
        let searchObject = searchCustomerParams
        searchObject.pageSize = 1000
        searchObject.cateringId = authContext.cateringId
        customersService.search(searchObject).then(data => {
            if (data?.items?.length > 0) {
                let customersForDropdown = data.items.map(x => ({
                    value: x.id, label: x.name
                }))
                setCustomersDropdownList(customersForDropdown)
            }
        })
    }
    const getItemCategories = (searchData) => {
        let searchObject = searchItemCategoryParams
        if (searchData) {
            searchObject = { ...searchItemCategoryParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        itemCategoryService.search(searchObject).then(data => {
            setItemCategories(sortArray(data.items, 'id'))
        })
    }
    const onSingleDateChange = (event) => {
        setMenuDay(event.value)
        let date = moment(event.value).format("DD/MM/YYYY");
        getActiveMenus(date, date)
    }
    const getActiveMenus = (first, last, defaultMenu) => {
        if (customer) {
            let searchObject = {
                ...searchMenuParams,
                minDate: defaultMenu ? "1900-01-01" : moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
                maxDate: defaultMenu ? "2100-01-01" : moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
                customerId: customer,
                cateringId: authContext.cateringId,
                defaultMenu: defaultMenu
            }
            setShowProgressBar(true)
            menuService.search(searchObject).then(data => {
                setShowProgressBar(false)
                let originalStateValue = [...data.items]
                let newStateValue = [...data.items]
                setOriginalMenusForCurrentWeek(JSON.stringify(originalStateValue))
                if (defaultMenu && data?.items?.[0]) {
                    setDefaultMenuForCustomer(data.items[0])
                } else {
                    setMenusForCurrentWeek(newStateValue)
                }
            }).catch(reason => {
                setShowProgressBar(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSearchMenu'), life: 3000 })
            })

        }
    }
    const getTemplateMenu = (customerValue) => {
        if (customerValue) {
            let searchObject = {
                ...searchMenuParams,
                minDate: "2000-01-01",
                maxDate: "2050-01-01",
                cateringId: authContext.cateringId,
                customerId: customerValue,
                template: true
            }
            setShowProgressBar(true)
            menuService.search(searchObject).then(data => {
                setShowProgressBar(false)
                setTemplateMenus(data.items)

            })
        }
    }
    const nextWeek = () => {
        let weekStart = moment(lastDayOfWeek, "DD/MM/YYYY").isoWeekday(7).add(1, 'day');
        returnAllDaysOfWeek(weekStart)
    }
    const previousWeek = () => {
        let weekStart = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(7, 'day');
        returnAllDaysOfWeek(weekStart)
    }
    const returnAllDaysOfWeek = (initialDate) => {
        let days = [];
        for (let i = 0; i <= 6; i++) {
            days.push(moment(initialDate).add(i, 'days').format("DD/MM/YYYY"));
        }
        setDaysOfWeek(days)
        setFirstDayOfWeek(days[0])
        setLastDayOfWeek(days[6])
    }
    const isPreviousWeekEnabled = () => {
        let currentWeekFirstDay = moment().isoWeekday(1);
        let firstDayOfWeekToCheck = moment(firstDayOfWeek, 'DD/MM/YYYY')
        return !currentWeekFirstDay.isSameOrAfter(firstDayOfWeekToCheck)
    }
    const isEditEnabled = (date) => {
        return isDateInEditRange(date, customerConfiguration?.numberOfDaysBeforeMenuIsActive, customerConfiguration?.numberOfDaysBeforeMenuIsActiveTime, customerConfiguration?.numberOfDaysBeforeMenuIsNotActive, customerConfiguration?.numberOfDaysBeforeMenuIsNotActiveTime, true, customerConfiguration?.dailyBasedOrder)
    }
    useEffect(() => {
        getItemCategories()
        getMyCustomers()
        let currentDate = moment();
        let weekStart = currentDate.isoWeekday(1);
        returnAllDaysOfWeek(weekStart)
    }, []);
    useEffect(() => {
        getActiveMenus(firstDayOfWeek, lastDayOfWeek)
        if (customer) {
            getTemplateMenu(customer)
            customersService.getCustomerCateringConfiguration(customer).then((data) => {
                setCustomerConfiguration(data)
            })
        }
    }, [customer, firstDayOfWeek]);
    useEffect(() => {
        if (menuType && menuType == "defaultMenu") {
            getActiveMenus(firstDayOfWeek, lastDayOfWeek, true)
        }
        if (menuType && menuType == "multiDay") {
            getActiveMenus(firstDayOfWeek, lastDayOfWeek)
        }
        if (menuType && menuType == "singleDay") {
            if (menuDay) {
                let date = moment(menuDay).format("DD/MM/YYYY");
                getActiveMenus(date, date)
            }
        }
    }, [menuType]);
    const onHideSelectMenuItemDialog = () => {
        setSelectedMenuItemsForSelectedCategory([])
        setShowDialog(false)
        setMenuItemsForSelectedCategory()
        setOriginalMenuItemsForSelectedCategory()
        setCurrentSelectedDate()
    }
    const onHidePreviewMenuDialog = () => {
        setShowMenuPreviewDialog(false)

    }
    const prepareDataForExport = (data, date) => {
        let menuForCurrentDate = data.find(x => x.menuDate == date)
        let groupedData = groupMenuItemsByCategoryId(menuForCurrentDate?.menuItemDTOList)

        let tableData = []
        groupedData.forEach(element => {
            element.elements.forEach(element1 => {
                tableData.push({
                    [element1.itemCategoryName]: element1.name
                })
            });
        });
        return tableData;
    }
    const prepareSheetsForExcel = (xlsx, menuForExport) => {
        let sheets = {}
        let sheetNames = []
        menuForExport.forEach(element => {
            const worksheet = xlsx.utils.json_to_sheet(prepareDataForExport(menuForExport, element.menuDate))
            var wscols = [
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 30 }
            ];
            worksheet["!cols"] = wscols;
            sheets[element.menuDate] = worksheet
            sheetNames.push(element.menuDate)
        });
        return { Sheets: sheets, SheetNames: sheetNames }
    }
    const exportExcel = (usersToExport) => {
        import('xlsx').then(xlsx => {
            const workbook = prepareSheetsForExcel(xlsx, usersToExport);
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            saveAsExcelFile(excelBuffer, 'user_data');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }
    const exportToExcel = () => {
        exportExcel(menusForCurrentWeek)
        console.log(menusForCurrentWeek, 'menusForCurrentWeek');
        // getAllUsers(filterData, true)
    }
    const returnTemplateMenus = (menu) => {
        return <div className='flex h-3rem min-h-3rem justify-content-between mb-2 border-bottom-1 border-gray-200'>
            <span>{menu.name}</span>
            <Button label={t('apply')} className='p-button-info p-button-outlined text-sm text-center' onClick={() => applyTemplateToDate(menuDay, menu)} />
        </div>
    }
    const returnMenuItemsForSelectedCategoryMini = (data) => {
        return <div className="col-12 md:col-2 lg:col-2 xl:col-2 ml-2 mb-4">
            <div className='flex p-2 border-1  border-400 surface-0 text-sm mx-2 min-w-5rem justify-content-between align-items-center' style={{ borderRadius: "20px" }}>
                <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} className="h-1rem m-2" style={{ objectFit: 'contain' }} />
                <span className='m-2'>{data.name}</span>
                <Checkbox inputId={data.id} className='mt-1' value={data.id} onChange={(e) => showDefaultMenu() ? onDialogDefaultMenuItemChange(e, data) : onDialogMenuItemChange(e, data)} checked={selectedMenuItemsForSelectedCategory.includes(data.id)}></Checkbox>
            </div>
        </div >
    }
    const returnMenuItemsForSelectedCategory = (data) => {
        return <div className="col-12 md:col-3 lg:col-3 xl:col-3">
            <div className="card m-3 border-1 surface-border menu-item-wrapper">
                <div className="flex align-items-center h-2rem">
                    <div className="flex align-items-center">
                        {
                            data?.itemCategoryImage ? <img src={"data:image/png;base64," + data.itemCategoryImage} alt={data.itemCategoryName} className="h-2rem" style={{ objectFit: 'contain' }} />
                                : <i className="pi pi-tag mr-2" />

                        }
                        <span className="font-semibold">{data.itemCategoryName}</span>
                    </div>
                    <div className='flex h-3rem min-h-3rem'>
                        <span className={`product-badge status`}>{data.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={data.name} className="h-2rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{data.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={data.name} className="h-2rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{data.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={data.name} className="h-2rem" />
                            : <></>}</span>
                    </div>
                </div>
                <div className="text-center">
                    <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="h-8rem my-3 mx-0 w-full" style={{ objectFit: 'contain' }} />
                    <div className="text-2xl font-bold">{data.name}</div>
                    <div className="mb-3 h-3rem">{data.description}</div>
                </div>
                <div className="div text-center">
                    <Checkbox inputId={data.id} value={data.id} onChange={(e) => showDefaultMenu() ? onDialogDefaultMenuItemChange(e, data) : onDialogMenuItemChange(e, data)} checked={selectedMenuItemsForSelectedCategory.includes(data.id)}></Checkbox>
                </div>
            </div>
        </div>
    }
    const returnMenuItemsForSelectedCategoryPreview = (data) => {
        return <div className='flex p-2 border-1  border-400 surface-0 text-sm mx-2 min-w-5rem' style={{ borderRadius: "20px" }}>
            <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} className="h-1rem m-2" style={{ objectFit: 'contain' }} />

            <span className='m-2'>{data.name}</span>
            <i className='pi pi-times m-2 cursor-pointer' onClick={() => onDialogMenuItemChange({ checked: false, value: data.id }, data)} />
        </div>
    }
    const getMenuItemsForCategoryMini = (data) => {
        return <div className='flex p-2 border-1 border-400 surface-0 text-sm mt-1 mx-2 w-full' style={{ borderRadius: "20px" }}>
            <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} className="h-2rem w-2rem m-1" style={{ objectFit: 'contain', borderRadius: "10px" }} />
            <span className='m-2 text-left'>{data.name}</span>
        </div>
    }
    const getMenuItemsForCategory = (singleMenuItemForCategory, single) => {
        let colValue = single && !isMobile() ? "col-2 p-2 ml-2" : "col-12"
        return <div className={colValue + " flex flex-column border-bottom-1 border-300 mb-2 menu-item-wrapper"}>
            <div className="surface-borders surface-0">
                <div className='flex h-3rem min-h-3rem'>
                    <span className={`product-badge status`}>{singleMenuItemForCategory.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={singleMenuItemForCategory.name} className="h-2rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{singleMenuItemForCategory.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={singleMenuItemForCategory.name} className="h-2rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{singleMenuItemForCategory.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={singleMenuItemForCategory.name} className="h-2rem" />
                        : <></>}</span>
                </div>
                <div className="text-center">
                    <img src={singleMenuItemForCategory.image ? "data:image/png;base64," + singleMenuItemForCategory.image : "assets/layout/images/blank_menu_item.jpg"} alt={singleMenuItemForCategory.name} className="h-7rem w-full my-1 mx-0" style={{ objectFit: 'contain' }} />
                    <div className="font-bold mt-1" style={isMobile() ? { fontSize: '6vw' } : { fontSize: '1vw' }}>{singleMenuItemForCategory.name}</div>
                    <div className="mb-3 h-3rem">{singleMenuItemForCategory.description}</div>
                </div>
            </div>
        </div>
    }
    const getMenuItemsForCategoryPreview = (singleMenuItemForCategory) => {
        return <div className='flex'>
            <img src={singleMenuItemForCategory.image ? "data:image/png;base64," + singleMenuItemForCategory.image : "assets/layout/images/blank_menu_item.jpg"} alt={singleMenuItemForCategory.name} className="h-2rem w-2rem mt-3 mr-2" style={{ objectFit: 'contain' }} />
            <div className="flex flex-column border-bottom-1 border-300 w-full text-left">
                <div className="surface-border">
                    <div className='flex '>
                        <div className="font-bold mt-1" style={isMobile() ? { fontSize: '3vw' } : { fontSize: '0.6vw' }}>{singleMenuItemForCategory.name}</div>
                        <span className={`product-badge status`}>{singleMenuItemForCategory.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={singleMenuItemForCategory.name} className="h-1rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{singleMenuItemForCategory.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={singleMenuItemForCategory.name} className="h-1rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{singleMenuItemForCategory.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={singleMenuItemForCategory.name} className="h-1rem" />
                            : <></>}</span>
                    </div>
                    <div className="text-left">
                        <div className="mb-3">{singleMenuItemForCategory.description}</div>
                    </div>
                </div>
            </div>
        </div>

    }
    const getCategoriesComponent = (date, single) => {
        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        if (showDefaultMenu() && defaultMenuForCustomer) {
            singleElement = defaultMenuForCustomer
        }
        let groupedElements = []
        if (singleElement?.menuItemDTOList) {
            groupedElements = groupMenuItemsByCategoryId(singleElement?.menuItemDTOList)
        }
        const editEnabled = isEditEnabled(date) || showDefaultMenu()
        if (itemCategories?.length > 0) {
            return <div className='grid'>
                {
                    itemCategories.map(x => {
                        let menuItemForCategory = groupedElements.filter(z => z.categoryId === x.id)
                        return <div className="col-12 min-h-14rem flex flex-column justify-content-center border-bottom-1 border-gray-200">
                            <div className="surface-border">
                                <div className="text-center">
                                    <div className='flex justify-content-between mb-3 pb-2'>
                                        <div className="mt-1 font-bold" style={isMobile() ? { fontSize: '5.2vw' } : { fontSize: '1.2vw' }}>{x.name}</div>
                                        <Button label={menuType == "singleDay" || menuType == "defaultMenu" ? (menuItemForCategory?.[0]?.elements?.length > 0 ? t('edit') : t('add')) : ''} disabled={!editEnabled} icon={menuItemForCategory?.[0]?.elements?.length > 0 ? "pi pi-pencil" : "pi pi-plus"} className='p-button-info p-button-outlined' onClick={() => {
                                            addNewItemForCategory(x.id, date, menuItemForCategory)
                                        }} />
                                    </div>
                                    <div className='grid'>
                                        {
                                            menuItemForCategory?.[0]?.elements?.map(c => {
                                                return single ? getMenuItemsForCategory(c, single) : getMenuItemsForCategoryMini(c)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        }
    }
    const getCategoriesComponentForPreview = (date) => {
        // let currentDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString();

        let singleElement = returnMenuForSpecificDate(menusForCurrentWeek, date)
        let groupedElements = []
        if (singleElement?.menuItemDTOList) {
            groupedElements = groupMenuItemsByCategoryId(singleElement?.menuItemDTOList)
        }
        if (singleElement && itemCategories?.length > 0) {
            return <div className='surface-50 p-2 m-2 max-w-10rem'>
                <div className='col-12'>
                    {/* {t(moment(date, "YYYY-MM-DD").format('dddd').substring(0, 3)) + " " + date} */}
                    {date}
                </div>
                <div className='col-12'>
                    <i className='pi pi-calendar' /> {t('dateActiveFrom')} : {moment(singleElement.dateActiveFrom).format('DD/MM/YYYY HH:mm')}
                </div>
                <div className='col-12'>
                    <i className='pi pi-calendar' /> {t('dateActiveTo')} : {moment(singleElement.dateActiveTo).format('DD/MM/YYYY HH:mm')}
                </div>
                {
                    itemCategories.map(x => {
                        let menuItemForCategory = groupedElements.filter(z => z.categoryId === x.id)
                        return <div className="col-12 flex flex-column ">
                            <div className="surface-border">
                                <div className="text-center">
                                    <div className='flex justify-content-between mb-3 pb-2'>
                                        <div className="mb-2" style={isMobile() ? { fontSize: '5.2vw' } : { fontSize: '1.2vw' }}>{x.name}</div>

                                    </div>
                                    {
                                        menuItemForCategory?.[0]?.elements?.map(c => {
                                            return getMenuItemsForCategoryPreview(c)
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        }
    }
    const showCalendarComponent = () => {
        if (customer && menuType == "multiDay") {
            return true
        }
        return false
    }
    const showSingleComponent = () => {
        if (customer && menuType == "singleDay" && menuDay) {
            return true
        }
        if (showDefaultMenu()) {
            return true
        }
        return false
    }
    const showDefaultMenu = () => {
        if (customer && menuType == "defaultMenu") {
            return true
        }
        return false
    }
    const filterData = (event) => {
        if (originalMenuItemsForSelectedCategory?.length === 0) {
            setOriginalMenuItemsForSelectedCategory(menuItemsForSelectedCategory)
        } else {
            setMenuItemsForSelectedCategory(originalMenuItemsForSelectedCategory)
        }
        const value = event.target.value.toLowerCase();
        const filteredItems = originalMenuItemsForSelectedCategory.filter((item) => item.name.toLowerCase().includes(value));
        if (value && value !== "") {
            setMenuItemsForSelectedCategory(filteredItems);
        } else {
            setMenuItemsForSelectedCategory(originalMenuItemsForSelectedCategory)
        }
    }
    const copyMenu = () => {
        let menuToCopy = menusForCurrentWeek.map(x => ({
            cateringId: x.cateringId,
            menuDate: x.menuDate,
            menuItemDTOList: x.menuItemDTOList
        }))
        localStorage.setItem('copiedMenu', JSON.stringify(menuToCopy))
        localStorage.setItem('copiedFirstDay', firstDayOfWeek)
        localStorage.setItem('copiedLastDay', lastDayOfWeek)
        toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuCopied'), life: 3000 })

    }
    const pasteMenu = () => {
        let menuFromStorage = JSON.parse(localStorage?.getItem('copiedMenu'))
        let dateActiveFromTime = customerConfiguration.numberOfDaysBeforeMenuIsActiveTime ? "T" + customerConfiguration.numberOfDaysBeforeMenuIsActiveTime + ":00.00" : "T10:00:00.00"
        let dateActiveToTime = customerConfiguration.numberOfDaysBeforeMenuIsNotActiveTime ? "T" + customerConfiguration.numberOfDaysBeforeMenuIsNotActiveTime + ":00.00" : "T13:00:00.00"
        let dateActiveFrom = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(customerConfiguration.numberOfDaysBeforeMenuIsActive ?? 7, 'day').format("YYYY-MM-DD").toString() + dateActiveFromTime
        let dateActiveTo = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(customerConfiguration.numberOfDaysBeforeMenuIsNotActive ?? 2, 'day').format("YYYY-MM-DD").toString() + dateActiveToTime
        if (customerConfiguration.dailyBasedOrder) {
            dateActiveTo = moment(currentSelectedDate, "DD/MM/YYYY").subtract(1, 'day').format("YYYY-MM-DD").toString() + dateActiveToTime
        }
        let menuToPaste = menuFromStorage?.map(x => ({
            ...x,
            customerId: customer,
            dateActiveFrom: dateActiveFrom,
            dateActiveTo: dateActiveTo,
            name: "Menu - " + moment(new Date(), "DD/MM/YYYY").toString(),
        }))
        setMenusForCurrentWeek(menuToPaste)

    }
    const isSaveEnabled = () => {
        let itemsChangedList = []
        menusForCurrentWeek.forEach(element => {
            if (isSingleElementChanged(element)) {
                itemsChangedList.push(element.name)
            }
        });
        // return itemsChangedList.length > 0
        return true
    }
    const isSingleElementChanged = (singleElement) => {
        const editEnabled = isEditEnabled(singleElement)
        if (originalMenusForCurrentWeek) {

            let currentDate = moment(singleElement.menuDate, "YYYY-MM-DD").format("DD/MM/YYYY").toString();

            let originalSingleElement = returnMenuForSpecificDate(JSON.parse(originalMenusForCurrentWeek), currentDate)
            if (singleElement?.menuItemDTOList
                && originalSingleElement?.menuItemDTOList) {
                if (singleElement.menuItemDTOList.length !== originalSingleElement.menuItemDTOList.length) {
                    return editEnabled
                }
                else {
                    let originalItemNames = originalSingleElement.menuItemDTOList.map(x => x.name)
                    let singleItemNames = singleElement.menuItemDTOList.map(x => x.name)
                    let isItemsChanged = false
                    singleItemNames?.forEach(element => {
                        if (!originalItemNames.includes(element)) {
                            isItemsChanged = editEnabled
                        }
                    });
                    return isItemsChanged
                }
            }
        }
        return editEnabled && singleElement !== undefined

    }
    const selectMenuItemDialogFooter = (
        <>
            <Button label={t('ok')} icon="pi pi-check" className="p-button-outlined" onClick={onHideSelectMenuItemDialog} />
        </>
    );
    const previewMenuDialogFooter = (
        <>
            <Button label={t("cancel")} icon="pi pi-times" className="p-button-text" onClick={onHidePreviewMenuDialog} />
            <Button label={t('confirm')} icon="pi pi-check" className="p-button-outlined" onClick={saveChanges} />
        </>
    );
    const returnCurrentSelectedMenuItemsForCategory = () => {
        if (originalMenuItemsForSelectedCategory?.length > 0) {
            let items = originalMenuItemsForSelectedCategory.filter(x => selectedMenuItemsForSelectedCategory.includes(x.id))
            if (items?.length > 0) {
                let component = items.map(x => {
                    return returnMenuItemsForSelectedCategoryPreview(x)
                })
                return <div className='w-full grid'>{component}</div>
            }
        }
    }
    const dataItemHeader = (
        <div className='grid p-2 m-2'>
            <div className='col-12'>
                <p>{t('selectedItems')}</p>
                {returnCurrentSelectedMenuItemsForCategory()}

            </div>
            <div className='col-12 text-right'>
                <div className='grid'>
                    <div className='col-9'>
                        <span className="block mt-2 md:mt-0 p-input-icon-right">
                            <i className="pi pi-search" />
                            <InputText className='w-full' type="search" onInput={(e) => filterData(e)} placeholder={t("search")} />
                        </span>
                    </div>
                    <div className='col-3'>
                        <div className="my-2">
                            <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                        </div>
                    </div>
                </div>


            </div>
        </div>

    );
    const menuPreviewHeader = (
        <div className='grid surface-100 p-2 m-2'>
            <div className='col-12'>
                <p>{t('selectedItems')} {daysOfWeek[0] + " - " + daysOfWeek[daysOfWeek.length - 1]}</p>
            </div>
        </div>

    );
    const settingsForm = (x, label) => (
        <>
            <Button label={label ? t('settings') : ''} icon="pi pi-cog" className="text-blue-300 ml-2 cursor-pointer p-button-outlined" onClick={(e) => onSettingsClick(e, x)} />

            {/* <button className="p-link" onClick={(e) => onSettingsClick(e, x)}>
                <i className="pi pi-cog text-blue-300 text-xl ml-2 cursor-pointer" />
            </button> */}
            <OverlayPanel dismissable={false} breakpoints={{ '960px': '75vw', '640px': '97vw' }} ref={op} showCloseIcon id="overlay_panel" style={{ width: '450px' }} className="overlaypanel-demo">
                <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">

                    <div className="field">
                        <label htmlFor="dateActiveFrom" className={classNames({ 'p-error': errors.dateActiveFrom })}>{t("dateActiveFrom")} *</label>
                        <Controller name="dateActiveFrom" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                            <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showTime showIcon />)} />
                        {/* {getFormErrorMessage('dateActiveFrom')} */}
                    </div>
                    <div className="field">
                        <label htmlFor="dateActiveTo" className={classNames({ 'p-error': errors.dateActiveTo })}>{t("dateActiveTo")} *</label>
                        <Controller name="dateActiveTo" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                            <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon showTime />)} />
                        {/* {getFormErrorMessage('dateActiveTo')} */}
                    </div>
                    <div className="field">
                        <label htmlFor="template">{t('template')}</label>
                        <Controller name="template" control={control} render={({ field, fieldState }) => (
                            <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                        )} />
                    </div>
                    <div className="field">
                        <label htmlFor="templateName">{t('name')}*</label>
                        <Controller name="templateName" control={control} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )} />
                    </div>
                    <div className='w-full text-right'>
                        <Button label={t('apply')} icon="pi pi-check" className="p-button-outlined w-8rem" />

                    </div>
                </form>
            </OverlayPanel>
        </>

    )
    return (
        <div className="App grid">
            {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px', width: "100%" }}></ProgressBar>}
            <Toast ref={toast} />
            <div className='col-12 grid card p-3 mt-1'>
                <div className='col-12 md:col-6 lg:col-6 xl:col-6 text-left'>
                    <div className="field">
                        <label htmlFor="customer">{t("customer")}</label>
                        <Dropdown id="customer" className='w-full' value={customer} options={customersDropdownList} onChange={(e) => onCustomerChange(e.value)} placeholder={t('selectCustomer')} />
                    </div>
                </div>
                {customer && <div className='col-12 md:col-6 lg:col-6 xl:col-6 text-right flex justify-content-end'>
                    <div className="field">
                        <label htmlFor="menuType">{t("")}</label>
                        <SelectButton className='text-center' id="menuType" value={menuType} options={menuTypeOptions} onChange={(e) => setMenuType(e.value)}></SelectButton>
                    </div>
                </div>}
            </div>
            {menuType == "singleDay" && <div className='col-12 grid card p-3'>
                <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
                    <Calendar id="menuDate" value={menuDay} className='custom-menu-calendar cursor-pointer' onChange={(e) => onSingleDateChange(e)} touchUI dateFormat='dd/mm/yy' />
                </div>
                <div className='col-12 md:col-6 lg:col-6 xl:col-6 flex justify-content-end'>
                    {settingsForm(moment(menuDay).format("DD/MM/YYYY"), true)}
                    <Button label={isMobile() ? null : t('save')} icon="pi pi-save" className="p-button-info ml-2" onClick={() => setShowMenuPreviewDialog(true)} disabled={!isSaveEnabled()} />
                </div>

            </div>}
            {showDefaultMenu() && <div className='col-12 grid card p-3'>

                <div className='col-12 flex justify-content-end'>
                    <Button label={isMobile() ? null : t('save')} icon="pi pi-save" className="p-button-info ml-2" onClick={() => saveDefaultMenu()} />
                </div>

            </div>}

            {
                showCalendarComponent() && <div className='col-12'>
                    <div className='grid card'>
                        <div className='col-3 text-center'>
                            <Button label={isMobile() ? null : t("previousWeek")} icon="pi pi-arrow-left" iconPos="left" onClick={previousWeek} disabled={!isPreviousWeekEnabled()} />
                        </div>
                        <div className='col-3 md:col-5 lg:col-5 text-center'>
                            {isMobile() ? <h6>{firstDayOfWeek} - {lastDayOfWeek}</h6> : <h4>{firstDayOfWeek} - {lastDayOfWeek}</h4>}
                        </div>
                        <div className='col-3 text-center'>
                            <Button label={isMobile() ? null : t("nextWeek")} icon="pi pi-arrow-right" iconPos="right" onClick={nextWeek} />
                        </div>
                        <div className='col-1 text-center'>
                            <Button label={isMobile() ? null : t('save')} icon="pi pi-save" className="p-button-info mr-2" onClick={() => setShowMenuPreviewDialog(true)} disabled={!isSaveEnabled()} />
                            {/* <Button label={t("export")} icon="pi pi-download" className="p-button-help mr-2" onClick={exportToExcel} /> */}

                        </div>

                    </div>
                </div>
            }
            {
                showCalendarComponent() && <div className='col-12'>
                    <ContextMenu model={contextMenuItems} ref={cm} />
                    <div className='grid lg:p-0 sm:p-3' onContextMenu={(e) => cm.current.show(e)}>
                        {
                            daysOfWeek.map(x => {
                                return <div className={isMobile() ? "card text-center m-3 p-3" : "card text-center p-2"} style={isMobile() ? { width: "100%" } : { width: 100 / 7 + "%" }}>
                                    <div className='grid'>
                                        <div className='col-12 text-center mt-2'>
                                            {t(moment(x, "DD/MM/YYYY").format('dddd').substring(0, 3)) + " " + x}
                                            {settingsForm(x, false)}
                                        </div>
                                    </div>
                                    <br />
                                    {getCategoriesComponent(x)}
                                </div>
                            })
                        }
                    </div>
                </div>
            }
            {
                showSingleComponent() &&
                <div className={showDefaultMenu() ? 'col-12 card' : 'col-9 card'} style={{ marginLeft: '-0.5rem' }}>
                    {getCategoriesComponent(moment(menuDay).format("DD/MM/YYYY"), true)}
                </div>
            }
            {
                showSingleComponent() && !showDefaultMenu() &&
                <div className='col-3 card'>
                    <div className='col-12'>
                        <div className="mb-2" style={isMobile() ? { fontSize: '5.2vw' } : { fontSize: '1.2vw' }}>{t('templateMenu')}</div>
                        {templateMenus?.length > 0 && templateMenus.map(singleMenu => {
                            return returnTemplateMenus(singleMenu)
                        })}
                    </div>
                </div>
            }
            <Dialog visible={showDialog} style={{ width: '90%', height: "90%" }} header={dataItemHeader} footer={selectMenuItemDialogFooter} modal onHide={onHideSelectMenuItemDialog}>
                <div className=''>
                    <DataView value={menuItemsForSelectedCategory} layout={'grid'}
                        itemTemplate={layout == "grid" ? returnMenuItemsForSelectedCategory : returnMenuItemsForSelectedCategoryMini} dataKey="id" className="datatable-responsive"
                        currentPageReportTemplate={t('defaultPaginationMessage')}
                        emptyMessage={t('noItemsFound')} responsiveLayout="scroll" />
                    {/* {
                        menuItemsForSelectedCategory?.map(x => {
                            return returnMenuItemsForSelectedCategory(x)
                        })
                    } */}
                </div>
            </Dialog>
            <Dialog visible={showMenuPreviewDialog} style={{ width: '95%', height: "90%" }} header={menuPreviewHeader} footer={previewMenuDialogFooter} modal onHide={onHidePreviewMenuDialog}>
                <div className='flex'>

                    {
                        daysOfWeek?.map(x => {
                            return getCategoriesComponentForPreview(x)
                        })
                    }
                </div>
            </Dialog>
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MenuClient, comparisonFn);
