import moment from "moment";
import { sortArray } from "./general";
export const calculateOrderPrice = (menuItems) => {
    let price = 0
    if(menuItems?.length > 0){
      menuItems.forEach(element => {
        price += element.price
      });
    }

    return price
  }
export const calculateOrderAmountPrice = (orderItems) => {
    let price = 0
    if(orderItems?.length > 0){
        orderItems.forEach(element => {
        price += element.amountPrice
      });
    }

    return price
  }
export const groupMenuItemsByCategoryId = (menuItems) => {
    let categories = []
    if (menuItems?.length > 0) {
        menuItems = sortArray(menuItems, 'itemCategoryId')
        menuItems.forEach(element => {
            if (!categories.includes(element.itemCategoryId)) {
                categories.push(element.itemCategoryId)
            }
        });
        let groupedItems = categories.map(element => {
            let arrayOfSingleElement = menuItems.filter(x => x.itemCategoryId === element)
            return {
                categoryId: element, elements: arrayOfSingleElement
            }
        });
        return groupedItems;
    }
    return []
}
export const returnMenuForSpecificDate = (menuList, date) => {
    if (date) {
        let filteredMenu = menuList.filter((x) => {
            let currentDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString();
            let dateFromMenu = moment(x.menuDate).format("YYYY-MM-DD").toString();
            return currentDate === dateFromMenu

        })
        if (filteredMenu?.length > 0) {
            return filteredMenu[0] || []
        }
    }
}