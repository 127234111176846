import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Ripple } from "primereact/ripple";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { classNames } from "primereact/utils";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context";
import CateringService from "../../service/CateringService";
import CustomerService from "../../service/CustomerService";
import OrderService from "../../service/OrderService";
import { sortArray, sortArrayD } from "../../utilities/general";
import FilterOrderForm from "./FilterOrderForm";
import PrintOrderData from "./PrintOrderData";
import { customerAdmin } from "../../constants/auth";
import useOrderDataTaskUtil from "../../utilities/useOrderDataTaskUtil";
import { calculateOrderAmountPrice, calculateOrderPrice } from "../../utilities/menuItemUtil";

const OrdersDataTask = () => {
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [orderTableData, setOrderTableData] = useState([]);
    const [orderData, setOrderData] = useState();
    const [orderStockItemsData, setOrderStockItemsData] = useState([]);
    const [orderCountData, setOrderCountData] = useState();
    const [orderCountDataAll, setOrderCountDataAll] = useState();
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [currentSelectedCustomer, setCurrentSelectedCustomer] = useState();
    const [currentSelectedMinDate, setCurrentSelectedMinDate] = useState();
    const [currentSelectedMaxDate, setCurrentSelectedMaxDate] = useState();
    const [customerData, setCustomerData] = useState();
    const [cateringData, setCateringData] = useState();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [currentSearchData, setCurrentSearchData] = useState({});
    const toast = useRef(null);
    const dt = useRef(null);
    const orderService = new OrderService();
    const customerService = new CustomerService();
    const cateringService = new CateringService();

    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const isCustomerUser = authContext?.roleDTO?.name == customerAdmin
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { returnNormCountOfItems } = useOrderDataTaskUtil()
    const onPage = (event) => {
        let searchParams = { ...currentSearchData }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        searchOrders(searchParams)
    }
    const prepareDataForTable = (data) => {
        let tableData = []
        tableData = data.map(x => {
            let allItems = ""
            if (x?.menuItems?.length > 0) {
                let arrayItems = sortArray(x.menuItems, 'itemCategoryId')
                arrayItems.forEach((element, index) => {
                    if (element.name) {
                        let separator = ", "
                        if (index === 0) {
                            separator = ""
                        }
                        allItems += (separator + element.name)
                    }
                });
            }

            return {
                date: x.date,
                customerName: x.customerName,
                customerLocationName: x.customerLocationName,
                userFullName: x.userFullName,
                userPersonalNumber: x.userPersonalNumber,
                shift: x.shift,
                status: t(x.status),
                menuItems: allItems,
                comment: x.comment,
                price: calculateOrderPrice(x?.menuItems),
            }
        })
        return tableData;
    }
    const prepareDataForExport = (data) => {
        let tableData = []
        tableData = data.map(x => {
            let allItems = ""
            if (x?.menuItems?.length > 0) {
                let arrayItems = sortArray(x.menuItems, 'itemCategoryId')
                arrayItems.forEach((element, index) => {
                    if (element.name) {
                        let separator = ", "
                        if (index === 0) {
                            separator = ""
                        }
                        allItems += (separator + element.name)
                    }
                });
            }
            return {
                [t('date')]: x.date,
                [t('customerName')]: x.customerName,
                [t('location')]: x.customerLocationName,
                [t('userFullName')]: x.userFullName,
                [t('userPersonalNumber')]: x.userPersonalNumber,
                [t('shift')]: x.shift,
                [t('date')]: x.date,
                [t('status')]: t(x.status),
                [t('menuItems')]: allItems,
                [t('comment')]: x.comment,
                [t('price')]: calculateOrderPrice(x?.menuItems),
            }
        })
        return tableData;
    }
    const searchOrders = (searchData = {}, isExport = false, exportType) => {
        setOrderData()
        setOrderCountData()
        setOrderTableData([])
        setOrderCountDataAll()
        setOrderStockItemsData()
        setCustomerData()
        setCurrentSelectedCustomer()
        setCurrentSelectedMinDate()
        setCurrentSelectedMaxDate()
        if (searchData.customerId) {
            setCurrentSelectedCustomer(searchData.customerId)
        }
        let searchObject = {
            cateringId: authContext.cateringId
        }
        if (searchData.minDate) {
            setCurrentSelectedMinDate(moment(searchData.minDate).format("DD/MM/YYYY").toString())
            searchObject.minDate = moment(searchData.minDate).format("YYYY-MM-DD").toString()

        }
        if (searchData.maxDate) {
            setCurrentSelectedMaxDate(moment(searchData.maxDate).format("DD/MM/YYYY").toString())
            searchObject.maxDate = moment(searchData.maxDate).format("YYYY-MM-DD").toString()

        }
        if (searchData.user) {
            searchObject.userId = searchData.user
        }
        if (searchData.shift) {
            searchObject.shift = searchData.shift
        }
        if (searchData.status) {
            searchObject.status = searchData.status
        }
        searchObject = { ...searchData, ...searchObject }
        if (isExport || searchData?.type === 'amount' || searchData?.type === 'norm') {
            searchObject.pageSize = 100000
        }
        setShowProgressBar(true)
        orderService.search(searchObject).then(data => {
            setShowProgressBar(false)
            if (data?.items) {
                if (searchData.type === 'single') {
                    handleSingleOrderSearchResult(data, isExport, exportType)
                } else if (searchData.type === 'amount') {
                    handleAmountOrderSearchResult(data, isExport, exportType)

                } else if (searchData.type === 'norm') {
                    handleNormOrderSearchResult(data, isExport, exportType)

                }
            }
        }).catch(reason => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSearchOrder'), life: 3000 })
        })
    }
    const handleSingleOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items)

            }
            if (exportType == "pdf") {
                exportPdf(data.items)
            }
        } else {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setOrderData(data.items)
            setOrderTableData(prepareDataForTable(data.items))
        }
    }
    const handleAmountOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items, true)

            }
            if (exportType == "pdf") {
                exportPdf(data.items, true)
            }
        } else {
            setOrderCountData(returnCountOfItems(data.items)?.data)
            setOrderCountDataAll(returnCountOfItems(data.items)?.count)
        }
    }
    const handleNormOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items, false, true)

            }
            if (exportType == "pdf") {
                exportPdf(data.items, false, true)
            }
        } else {
            setOrderStockItemsData(sortArrayD(returnNormCountOfItems(data?.items), 'amount'))
        }
    }
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("orders")}</h5>
        </div>
    );
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.customerName}
            </>
        );
    }
    const locationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("location")}</span>
                {rowData.customerLocationName}
            </>
        );
    }
    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.date}
            </>
        );
    }

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.userFullName}</span>
            </>
        )
    }
    const commentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("comment")}</span>
                <span className={`status`}>{rowData.comment}</span>
            </>
        )
    }
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("price")}</span>
                <span className={`status`}>{rowData.price} RSD</span>
            </>
        )
    }
    const priceAmountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("price")}</span>
                <span className={`status`}>{rowData.amountPrice} RSD</span>
            </>
        )
    }
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("status")}</span>
                <span className={`status`}>{t(rowData.status)}</span>
            </>
        )
    }
    const menuItemsBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{t("description")}</span>
            <span className={`status`}>{rowData.menuItems}</span>
        </>
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => { }} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => { }} />
            </div>
        );
    }
    const onPrint = () => {
        setShowProgressBar(true)
        customerService.get(currentSelectedCustomer).then((data) => {
            setCustomerData(data)
            if (isCustomerUser) {
                setShowProgressBar(false)
                setShowPrintDialog(true)
            } else {
                cateringService.get(authContext.cateringId).then((cateringData) => {
                    setCateringData(cateringData)
                    setShowProgressBar(false)
                    setShowPrintDialog(true)
                })
            }

        })
    }
    const exportPdf = (dataToExport, amount, norm) => {
        let cols = [
            { field: 'date', header: t('date') },
            { field: 'customerName', header: t('customerName') },
            { field: 'customerLocationName', header: t('location') },
            { field: 'userFullName', header: t('userFullName') },
            { field: 'userPersonalNumber', header: t('userPersonalNumber') },
            { field: 'shift', header: t('shift') },
            { field: 'status', header: t('status') },
            { field: 'menuItems', header: t('menuItems') },
        ];
        if (amount) {
            cols = [
                { field: 'name', header: t('name') },
                { field: 'amount', header: t('amount') },
            ];
        }
        if (norm) {
            cols = [
                { field: 'name', header: t('name') },
                { field: 'amount', header: t('amount') },
                { field: 'unit', header: t('unit') },
            ];
        }
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                let data = []
                if (amount) {
                    data = returnCountOfItems(dataToExport)?.data
                } else if (norm) {
                    data = returnNormCountOfItems(dataToExport)
                } else {
                    data = prepareDataForTable(dataToExport)
                }
                doc.autoTable(exportColumns, data);
                doc.save(createFileName() + ".pdf");
            })
        })
    }
    const createFileName = () => {
        if (currentSearchData) {
            return t('orders') + " " + new Date().getTime()
        }
    }
    const exportExcel = (orderDataToExport, amount, norm) => {
        import('xlsx').then(xlsx => {
            let data = []
            if (amount) {
                data = returnCountOfItems(orderDataToExport)?.data
            } else if (norm) {
                data = returnNormCountOfItems(orderDataToExport)
            } else {
                data = prepareDataForExport(orderDataToExport)
            }
            const worksheet = xlsx.utils.json_to_sheet(data);
            var wscols = [
                { wch: 15 },
                { wch: 30 },
                { wch: 30 },
                { wch: 10 },
                { wch: 10 },
                { wch: 30 },
            ];
            worksheet["!cols"] = wscols;

            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            saveAsExcelFile(excelBuffer, createFileName());
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }
    const exportData = (searchParms, fileType) => {
        setCurrentSearchData(searchParms)
        searchOrders(searchParms, true, fileType)
    }
    const returnCountOfItems = (orderDataArray) => {
        let countData = []
        let allMenuItemNames = []
        let allMenuItems = []
        if (orderDataArray?.length > 0) {
            orderDataArray.forEach(element => {
                allMenuItems = [...allMenuItems, ...element.menuItems]
            });
            if (allMenuItems?.length > 0) {
                allMenuItems.forEach(element => {
                    if (!allMenuItemNames.includes(element.name)) {
                        allMenuItemNames.push(element.name)
                    }
                });
                if (allMenuItemNames?.length > 0) {
                    countData = allMenuItemNames.map(x => ({
                        name: x,
                        category: allMenuItems.find(y => y.name == x)?.itemCategoryName,
                        categoryAmount: allMenuItems.filter(y => y.itemCategoryName == allMenuItems.find(z => z.name == x)?.itemCategoryName)?.length,
                        categoryPrice: calculateOrderPrice(allMenuItems.filter(y => y.itemCategoryName == allMenuItems.find(z => z.name == x)?.itemCategoryName)),
                        amount: allMenuItems.filter(y => y.name === x).length,
                        amountPrice: calculateOrderPrice(allMenuItems.filter(y => y.name === x)),
                    }))
                }
            }
        }
        return {
            data: countData,
            count: allMenuItems?.length
        }

    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={t("export")} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
                <Button label={t("print")} icon="pi pi-print" className="p-button-help" onClick={() => onPrint()} disabled={orderTableData.length === 0} />
            </React.Fragment>
        )
    }
    const amountFooter = () => {
        return (
            <div className="flex">
                <div><span>{t('count')}</span>: {orderCountDataAll}
                <div className="m-3 ml-4 font-bold">{t('amountPrice')} : {calculateOrderAmountPrice(orderCountData)} RSD</div>

                </div>
            </div>
        )
    }
    const groupHeaderTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text font-bold">{data?.category}</span>
            </React.Fragment>
        );
    }

    const groupFooterTemplate = (data) => {
        return (
            <div className="">
                <div className="m-3 ml-4 font-bold">{t('count')} : {data?.categoryAmount}</div>
                <div className="m-3 ml-4 font-bold">{t('amountPrice')} : {data?.categoryPrice} RSD</div>
            </div>
        );
    }
    return (
        <div className="grid crud-demo">
            {!showPrintDialog ? <div className="col-12">
                <div className="card col-12 p-4">
                    <FilterOrderForm searchOrders={searchOrders} setCurrentSearchData={setCurrentSearchData} exportData={exportData} />
                </div>
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    {orderData && <DataTable ref={dt} value={orderTableData}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" >
                        {/* <Column sortable headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="date" header={t("date")} body={dateBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="customerName" header={t("customerName")} body={nameBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="customerLocationName" header={t("location")} body={locationBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="userFullName" header={t("userFullName")} body={usernameBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="shift" header={t("shift")} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="status" header={t("status")} body={statusBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="menuItems" header={t("menuItems")} body={menuItemsBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="comment" header={t("comment")} body={commentBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="price" header={t("price")} body={priceBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        {/* <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column> */}
                    </DataTable>}
                    {orderCountData && <DataTable emptyMessage={t('noItemsFound')} sortMode="single" sortField="category" sortOrder={1} rowGroupFooterTemplate={groupFooterTemplate} rowGroupHeaderTemplate={groupHeaderTemplate} rowGroupMode="subheader" groupRowsBy="category" value={sortArrayD(orderCountData, 'amount')} responsiveLayout="scroll" footer={amountFooter}>
                        <Column field="name" header={t('name')}></Column>
                        <Column field="amount" header={t('amount')}></Column>
                        <Column field="amountPrice" header={t('amountPrice')} body={priceAmountBodyTemplate}></Column>
                    </DataTable>}
                    {orderStockItemsData && <DataTable emptyMessage={t('noItemsFound')} value={orderStockItemsData}>
                        <Column field="name" header={t('name')}></Column>
                        <Column field="amount" header={t('amount')}></Column>
                        <Column field="unit" header={t('unit')}></Column>
                    </DataTable>}
                </div>
            </div>
                : <div className="col-12"><PrintOrderData orderData={orderData} orderTableData={orderTableData} closePrint={() => setShowPrintDialog(false)} cateringData={cateringData} customerData={customerData} minDate={currentSelectedMinDate} maxDate={currentSelectedMaxDate} /></div>

            }
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OrdersDataTask, comparisonFn);