import { JWT_NAME } from "../constants/auth"
import { getCookie } from "../utilities/general"

export const BLCUrl = "https://catering-bls-test-ffba5df5f1e5.herokuapp.com"
// export const BLCUrl = "http://DESKTOP-4QD5I5B:5000"
const token = getCookie(JWT_NAME)
export const defaultHeader = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
} 