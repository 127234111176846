import axios from 'axios';
import { BLCUrl, defaultHeader } from './serviceUtil';
const resourceName = "/user"

export default class UserService {
    save(requestBody) {
        return axios.post(BLCUrl + resourceName, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    update(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    confirmAccount(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/confirmAccount/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    resetPassword(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/resetPassword/" + id, {}, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    search(searchData) {
        let searchParams = "?"
        if (searchData?.username) {
            searchParams += "username=" + searchData.username + "&"
        }
        if (searchData?.name) {
            searchParams += "name=" + searchData.name + "&"
        }
        if (searchData?.status) {
            searchParams += "status=" + searchData.status + "&"
        }
        if (searchData?.cateringId) {
            searchParams += "cateringId=" + searchData.cateringId + "&"
        }
        if (searchData?.customerId) {
            searchParams += "customerId=" + searchData.customerId + "&"
        }
        if (searchData?.locationId) {
            searchParams += "locationId=" + searchData.locationId + "&"
        }
        if (searchData?.roleName) {
            searchParams += "roleName=" + searchData.roleName + "&"
        }
            searchParams += "pageNumber=" + searchData.pageNumber
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        return axios.get(BLCUrl + resourceName + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    get(id) {
        return axios.get(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    getCustomerUserByCard(cardNumber, customerId) {
        return axios.get(BLCUrl + resourceName + "/byCard?cardNumber=" + cardNumber + "&customerId=" + customerId , { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    delete(id) {
        return axios.delete(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    isUnique(username) {
        return axios.get(BLCUrl + resourceName + "/unique/" + username, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}
