import axios from "axios";
import { BLCUrl, defaultHeader } from "./serviceUtil";
const resourceName = "/auth"

export default class AuthService {
    checkSession() {
        return axios.get(BLCUrl + resourceName + "/session", { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    login(requestBody) {
        return axios.post(BLCUrl + resourceName + "/login", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    loginExternal(requestBody) {
        return axios.post(BLCUrl + resourceName + "/loginExternal", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    getUserData() {
        return axios.get(BLCUrl + resourceName + "/user", { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}