import axios from 'axios';
import { BLCUrl, defaultHeader } from './serviceUtil';
const resourceName = "/menu/item"

export default class MenuItemService {
    save(requestBody) {
        return axios.post(BLCUrl + resourceName, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    update(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    search(searchData) {
        let searchParams = "?"
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        if (searchData?.isPublic) {
            searchParams += "&isPublic=" + searchData.isPublic
        }
        if (searchData?.name) {
            searchParams += "&name=" + searchData.name
        }
        if (searchData?.itemCategoryId) {
            searchParams += "&itemCategoryId=" + searchData.itemCategoryId
        }
            searchParams += "&pageNumber=" + searchData.pageNumber
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        return axios.get(BLCUrl + resourceName + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    get(id) {
        return axios.get(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    delete(id) {
        return axios.delete(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    saveRecipe(requestBody) {
        return axios.post(BLCUrl + resourceName + "/recipe", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}
