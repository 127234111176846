import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from '../../service/UserService';
import { searchUserParams } from '../../service/serviceObjects';
import AddUserForm from './AddUserForm';
import { useHistory } from "react-router-dom";
import { isMobile } from '../../utilities/general';
import { Menu } from 'primereact/menu';

const UserTable = (props) => {
    const { customSearchData, customerData } = props;
    const [newUserDialog, setNewUserDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingTable, setLoadingTable] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null)
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null)
    const [filterData, setFilterData] = useState({})
    const [statusValue, setStatusValue] = useState()
    const [locationValue, setLocationValue] = useState()
    const [currentUserForEdit, setCurrentUserForEdit] = useState()

    let history = useHistory();
    const { t } = useTranslation();
    const toast = useRef(null);
    const dt = useRef(null);
    const menu = useRef(null);
    const userService = new UserService();
    let locations = []
    if (customerData?.locationDTOS?.length > 1) {
        locations = customerData?.locationDTOS.map(element => ({
            label: element.locationName,
            value: element.id
        }))
    }
    const prepareDataForExport = (data) => {
        let tableData = []
        tableData = data.map(x => {
            return {
                [t('name')]: x.name,
                [t('username')]: x.username,
                [t('email')]: x.email,
                [t('cardNumber')]: x.cardNumber,
                [t('personalNumber')]: x.personalNumber,
                [t('status')]: t(x.status)
            }
        })
        return tableData;
    }
    const exportExcel = (usersToExport) => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(prepareDataForExport(usersToExport));
            var wscols = [
                { wch: 30 },
                { wch: 30 },
                { wch: 30 },
                { wch: 20 },
                { wch: 10 }];
            worksheet["!cols"] = wscols;

            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            saveAsExcelFile(excelBuffer, 'user_data');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }
    useEffect(() => {
        getAllUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const openNew = () => {
        setNewUserDialog(true);
    }
    const onPage = (event) => {
        let searchParams = searchUserParams
        if (filterData) {
            searchParams = { ...searchParams, ...filterData }
        }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllUsers(searchParams)
    }
    const saveUser = (data) => {
        if (data) {
            let submitRequest = { ...data }
            submitRequest.status = "CREATED"
            if (data.roleId) {
                submitRequest.roleDTO = {
                    id: data.roleId
                }
            }
            userService.save(submitRequest).then((value) => {
                setNewUserDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('userAdded'), life: 3000 })
                getAllUsers()
                setUserToEdit()
            }).catch(reason => {
                setNewUserDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingUser'), life: 3000 })
            })
        }
    }
    const updateUser = (data) => {
        if (data) {
            let submitRequest = { ...data }
            if (data.roleId) {
                submitRequest.roleDTO = {
                    id: data.roleId
                }
            }
            userService.update(userToEdit.id, submitRequest).then((value) => {
                setNewUserDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('userUpdated'), life: 3000 })
                getAllUsers()
                setUserToEdit()
            })
        }
    }
    const addNewFilter = (key, value) => {
        setFilterData({ ...filterData, [key]: value })
    }
    const exportToExcel = () => {
        getAllUsers(filterData, true)
    }
    const getAllUsers = (searchData, isExport) => {
        setLoadingTable(true)
        let searchObject = searchUserParams
        if (searchData) {
            searchObject = { ...searchUserParams, ...searchData }
        }
        if (customSearchData) {
            searchObject = { ...searchObject, ...customSearchData }
        }
        if (isExport) {
            searchObject.pageSize = 100000
        }
        userService.search(searchObject).then(data => {
            setLoadingTable(false)
            if (isExport) {
                exportExcel(data.items)
            } else {
                setFirst(data.pageNumber * data.pageSize);
                setRows(data.pageSize);
                setTotalRecords(data.itemCount)
                setUsers(data.items)
            }
        })

    }
    const editUser = (item) => {
        setShowProgressBar(true)
        userService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setUserToEdit(data)
            setNewUserDialog(true);
        })
    }
    const hideDialog = () => {
        if (userToEdit) {
            setUserToEdit()
        }
        setNewUserDialog(false);
    }
    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }
    const confirmDeleteUser = (item) => {
        setUserToDelete(item);
        setDeleteUserDialog(true);
    }
    async function deleteSelectedUser() {
        userService.delete(userToDelete.id).then(data => {
            setUserToDelete(null);
            setDeleteUserDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('userDeleted'), life: 3000 });
            getAllUsers()
        }).catch(reason => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringDeleteUser'), life: 3000 })
        })
    }
    const restartPassword = (user) => {
        setShowProgressBar(true)
        userService.resetPassword(user.id).then(data => {
            setShowProgressBar()
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('passwordRestarted'), life: 3000 });
            getAllUsers()
        })
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("export")} icon="pi pi-download" className="p-button-help mr-2" onClick={exportToExcel} />
                </div>
            </React.Fragment>
        )
    }
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    let statusOptions = [
        {
            label: t('CREATED'), value: 'CREATED'
        },
        {
            label: t('CONFIRMED'), value: 'CONFIRMED'
        }
    ]
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageUsers")}</h5>
            <div className={isMobile() ? 'flex-row' : 'flex'}>
                <span className="block mt-2 mx-2 md:mt-0 sm-mt-1 p-input-icon-left">
                    <i className="pi pi-user" />
                    <InputText onInput={(e) => addNewFilter('name', e.target.value)} placeholder={t("name")} />
                </span>
                {customerData && <span className="block mt-2 mx-2 md:mt-0 sm-mt-1 p-input-icon-left">
                    <Dropdown showClear value={locationValue} options={locations} onChange={(e) => {
                        setLocationValue(e.value)
                        addNewFilter('locationId', e.value)
                    }} placeholder={t("location")} />
                </span>}
                <span className="block mt-2 mx-2 md:mt-0 sm-mt-1 p-input-icon-left">
                    <i className="pi pi-user" />
                    <Dropdown showClear value={statusValue} options={statusOptions} onChange={(e) => {
                        setStatusValue(e.value)
                        addNewFilter('status', e.value)
                    }} placeholder={t("status")} />
                </span>

                <Button icon="pi pi-filter" label={t('filter')} onClick={() => getAllUsers(filterData)} />
            </div>

        </div>
    )
    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("username")}</span>
                {rowData.username}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("email")}</span>
                <span className={`status`}>{rowData.email}</span>
            </>
        )
    }
    const statusTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("status")}</span>
                <span className={`status`}>{t(rowData.status)}</span>
            </>
        )
    }
    const locationTemplate = (rowData) => {
        let locationName = ""
        if (locations?.length > 0) {
            locationName = locations.find(x => x.value == rowData.customerLocationId)?.label
        }
        return (
            <>
                <span className="p-column-title">{t("location")}</span>
                <span className={`status`}>{locationName}</span>
            </>
        )
    }
    const items = [
        {
            label: t('options'),
            items: [
                {
                    label: t('edit'),
                    icon: 'pi pi-pencil',
                    command: () => {
                        editUser(currentUserForEdit)
                    }
                },
                {
                    label: t('resetPassword'),
                    icon: 'pi pi-times',
                    command: () => {
                        restartPassword(currentUserForEdit)
                    }
                }
            ]
        }
    ];
    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions mt-2 text-center">
                <Menu model={items} popup ref={menu} id="popup_menu" />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={(event) => {
                    setCurrentUserForEdit(rowData)
                    menu.current.toggle(event)
                }} aria-controls="popup_menu" aria-haspopup />
                {rowData?.roleDTO?.name?.toLowerCase()?.includes("customer") && <Button icon="pi pi-chart-pie" className="p-button-rounded p-button-info mr-2" onClick={() => history.push({
                    pathname: '/customerOrder',
                    state: { ...rowData }
                })} />}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteUser(rowData)} />

            </div>
        );
    }
    const deleteUserDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUser} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={users} selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header={t("name")} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="username" header={t("username")} body={usernameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="email" header={t("email")} body={emailBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column hidden={!customerData} field="location" header={t("location")} body={locationTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="status" header={t("status")} body={statusTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    {newUserDialog && <Dialog visible={newUserDialog} style={{ width: '650px' }} header={t("userForm")} modal onHide={hideDialog}>
                        <AddUserForm saveUser={saveUser} updateUser={updateUser} userToEdit={userToEdit} cateringId={customSearchData?.cateringId} customerId={customSearchData?.customerId} showRoles showCardField customerData={customerData} />
                    </Dialog>}
                    <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {userToDelete && <span>{t("areYouSureYouWantToDelete")} <b>{userToDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default UserTable;