import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import enImage from "../../assets/flags/en.jpg";
import srbImage from "../../assets/flags/srb.png";
import { JWT_NAME } from '../../constants/auth';
import i18n from '../../i18n';
import AuthService from '../../service/AuthService';
import externalAuthService from '../../service/ExternalAuthService';
import { createCookie, getCookie } from '../../utilities/general';
import MicrosoftAuthComponent from './MicrosoftAuthComponent';

const LoginComponent = () => {
    const authService = new AuthService();
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [errorMessage, seErrorMessage] = useState()
    const [langValue, setLangValue] = useState(getCookie('lang') || "srb");
    const [azureLogin, setAzureLogin] = useState(false)
    let defaultValues = {
        username: '',
        password: '',
        rememberMe: '',
    }
    const { control, formState: { errors }, handleSubmit, setError, reset } = useForm({ defaultValues: defaultValues, mode: 'all' });
    useEffect(() => {
        reset()
    }, [azureLogin])
    const submitFunction = (data) => {
        seErrorMessage()
        setShowProgressBar(true)
        authService.login(data).then(response => {
            createCookie(JWT_NAME, response.accessToken, 1)
            window.location = "/"
        }).catch((error) => {
            if (error?.response?.data === "LICENSE_NOT_VALID") {
                seErrorMessage(error?.response?.data)
            } else {
                setError('username', { type: 'custom', message: 'Bad credentials' });
                setError('password', { type: 'custom', message: 'Bad credentials' });
            }

        }).finally(() => {
            setShowProgressBar(false)

        })
    }
    const submitAzureFunction = (data, customerId) => {
        seErrorMessage()
        setShowProgressBar(true)
        let userData = {
            name: data.name,
            username: data.username,
            email: data.username,
            customerId: customerId ?? ''
        }
        authService.loginExternal(userData).then(response => {
            createCookie(JWT_NAME, response.accessToken, 1)
            window.location = "/"
        }).catch((error) => {
            if (error?.response?.data === "LICENSE_NOT_VALID") {
                seErrorMessage(error?.response?.data)
            } else {
                seErrorMessage("errorWithExternalLogin")

            }

        }).finally(() => {
            setShowProgressBar(false)

        })
    }
    const langOptions = [
        { img: srbImage, value: 'srb' },
        { img: enImage, value: 'en' },
    ];
    const { t } = useTranslation();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const changeLanguage = (lng) => {
        createCookie('lang', lng)
        setLangValue(lng)
        i18n.changeLanguage(lng);
    }
    const langTemplate = (option) => {
        return <img src={option.img} alt={"langImage"} width="20" height="10" />;

    }
    const fetchUser = async (authData) => {
        const user = await externalAuthService.login(authData.clientId, authData.tenantId);
        if (user) {
            submitAzureFunction(user, authData.customerId)
        }
    };
    return (
        <div className="flex align-items-center justify-content-center login-page h-screen">
            {!azureLogin && <div className="surface-card p-4 m-2 shadow-2 border-round w-full lg:w-3">
                <div className="text-center mb-5">
                    <img src="assets/layout/images/LOGO.svg" alt="hyper" height={90} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">{t('loginLabel')}</div>
                </div>
                <div>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    {errorMessage && <Message severity="error" text={t(errorMessage)} className='w-full' />}
                    <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">

                        <div className="field">
                            <label htmlFor="username" className={classNames({ 'p-error': errors.name })}>{t('username')}*</label>
                            <Controller name="username" control={control} rules={{ required: t('usernameRequired') }} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('username')}
                        </div>
                        <div className="field">
                            <label htmlFor="password" className={classNames({ 'p-error': errors.name })}>{t('password')}*</label>
                            <Controller name="password" control={control} rules={{ required: t('passwordRequired') }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className='flex justify-content-between'>
                            <Button className='loginBtn' label={t('login')} icon="pi pi-user" />
                        </div>
                        <div className="field mt-3">
                            <label htmlFor="rememberMe" className={classNames({ 'p-error': errors.rememberMe })}>{t('rememberMe')}</label>
                            <Controller name="rememberMe" control={control} render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className="rememberMe" />
                                // <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                        </div>


                    </form>
                </div>
                <div className='flex justify-content-between'>
                    <div className='text-right my-2'>
                        <a href='https://play.google.com/store/apps/details?id=com.radovanovica.cateringmobile'><img src={"assets/layout/images/google-play.png"} alt={"google play"} height={"30px"} /></a><br />
                        <a href='https://apps.apple.com/us/app/gfood-serbia/id6474066832'><img src={"assets/layout/images/app-store.png"} alt={"google play"} height={"30px"} /></a>
                    </div>
                    <div className='flex'>
                        <SelectButton value={langValue} options={langOptions} onChange={(e) => changeLanguage(e.value)} itemTemplate={langTemplate} optionLabel="value" className='text-right my-5' />
                    </div>
                </div>
                <div className='login-external-button text-center cursor-pointer p-1' onClick={() => setAzureLogin(true)}>
                    <img src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png" alt="Sign in with Microsoft" />
                    <span className='ml-2'>{t('loginWIthMicrosoft')}
                    </span>
                </div>
            </div>}

            <MicrosoftAuthComponent errorMessage={errorMessage} showDialog={azureLogin} loginFunction={fetchUser} onBack={() => { setAzureLogin(false) }} />
        </div>
    )
}

export default LoginComponent;
