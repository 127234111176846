import { useContext } from "react";
import AuthContext from "../context";
import { adminRole, cateringAdmin, cateringOperator, customerAdmin, customerOperator, customerPublicUser, customerUser } from "../constants/auth"
import { useTranslation } from "react-i18next";
export const useCustomMenu = () => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);

    function returnMenuList() {
        if (authContext?.roleDTO?.name) {
            switch (authContext?.roleDTO?.name) {
                case adminRole:
                    return [
                        {
                            label: t('homePage'), icon: 'pi pi-home',
                            items: [
                                { label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/' }
                            ]
                        },
                        {
                            label: t('catering'), icon: 'pi pi pi-globe',
                            items: [
                                { label: t('catering'), icon: 'pi pi-globe', to: '/catering' },
                                { label: t('license'), icon: 'pi pi-globe', to: '/license' },
                            ]
                        }
                    ]
                case cateringOperator:
                    return [
                        {
                            label: t('verificationClient'), icon: 'pi pi-check-square',
                            items: [
                                { label: t('verificationClient'), icon: 'pi pi-check-square', to: '/verificationClient' },
                            ]
                        },
                        {
                            label: t('customers'), icon: "pi pi-prime",
                            items: [
                                { label: t('orders'), icon: "pi pi-history", to: "/orders" }
                            ]
                        },
                    ]
                case cateringAdmin:
                    return [
                        {
                            label: t('homePage'), icon: 'pi pi-fw pi-home',
                            items: [
                                { label: t('dashboard'), icon: 'pi pi-fw pi-home', to: '/' }
                            ]
                        },
                        {
                            label: t('myCatering'), icon: 'pi pi-globe',
                            items: [
                                { label: t('myCatering'), icon: 'pi pi-globe', to: '/cateringOverview' },
                            ]
                        },
                        {
                            label: t('menu'), icon: 'pi pi-chart-pie',
                            items: [
                                { label: t('itemCategory'), icon: 'pi pi-tags', to: '/itemCategory' },
                                { label: t('menuItemAttribute'), icon: 'pi pi-tags', to: '/menuItemAttribute' },
                                { label: t('menuItems'), icon: 'pi pi-chart-pie', to: '/menuItems' },
                                // { label: t('previousMenus'), icon: 'pi pi-fw pi-server', to: '/menu' },
                                // { label: t('active'), icon: 'pi pi-fw pi-server', to: '/activeMenu' },
                                { label: t('menuClient'), icon: 'pi pi-calendar-plus', to: '/menuClient' },
                            ]
                        },
                        {
                            label: t('customers'), icon: "pi pi-prime",
                            items: [
                                { label: t('customers'), icon: 'pi pi-link', to: '/customers' },
                                { label: t('orders'), icon: "pi pi-history", to: "/orders" }
                            ]
                        },
                        {
                            label: t('stock'), icon: "pi pi-list",
                            items: [
                                { label: t('stockItem'), icon: 'pi pi-list', to: '/stockItem' },
                                { label: t('stockImport'), icon: "pi pi-download", to: "/stock" }
                            ]
                        },

                        {
                            label: t('verificationClient'), icon: 'pi pi-check-square',
                            items: [
                                { label: t('verificationClient'), icon: 'pi pi-check-square', to: '/verificationClient' },
                            ]
                        }
                    ]
                case customerAdmin:
                    return [
                        {
                            label: t('homePage'), icon: 'pi pi-home',
                            items: [
                                { label: t('dashboard'), icon: 'pi pi-home', to: '/' }
                            ]
                        },
                        {
                            label: t('myCompany'), icon: 'pi pi-globe',
                            items: [
                                { label: t('myCompany'), icon: 'pi pi-globe', to: '/customerOverview' },
                            ]
                        },
                        {
                            label: t('orderClient'), icon: 'pi pi-chart-pie',
                            items: [
                                { label: t('orderClient'), icon: 'pi pi-chart-pie', to: '/orderClient' },
                                { label: t('orders'), icon: "pi pi-history", to: "/orders" }
                            ]
                        },
                    ]
                case customerUser:
                    return [
                        {
                            label: t('homePage'), icon: 'pi pi-home',
                            items: [
                                { label: t('dashboard'), icon: 'pi pi-home', to: '/' }
                            ]
                        },
                        {
                            label: t('orderClient'), icon: 'pi pi-chart-pie',
                            items: [
                                { label: t('orderClient'), icon: 'pi pi-chart-pie', to: '/orderClient' },
                            ]
                        }
                    ]
                case customerOperator:
                    return [
                        { label: t('verificationClientCard'), icon: 'pi pi-qrcode', to: '/verificationClientCard' },

                    ]
                case customerPublicUser:
                    return [
                        {
                            label: t('orderClient'), icon: 'pi pi-chart-pie',
                            items: [
                                { label: t('orderClient'), icon: 'pi pi-chart-pie', to: '/' },
                            ]
                        },
                    ]

                default: return []
            }
        }
    }
    return {
        returnMenuList
    }
}
