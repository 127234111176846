import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { customerAdmin } from '../../constants/auth';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import { searchCustomerParams, searchUserParams } from '../../service/serviceObjects';
import UserService from '../../service/UserService';
import { Menu } from 'primereact/menu';
import { MultiSelect } from 'primereact/multiselect';

const FilterOrderForm = (props) => {
    const { searchOrders, setCurrentSearchData, exportData } = props
    let defaultValues = {
        name: '',
        // shift: '',
        user: '',
        customerId: '',
        category: '',
        minDate: '',
        maxDate: '',
        type: 'single',
        status: ''
    }
    const customerService = new CustomerService();
    const userService = new UserService();

    const [myCustomers, setMyCustomers] = useState([])
    const [myCustomersLocations, setMyCustomersLocations] = useState([])
    const [customerUsers, setCustomerUsers] = useState([])
    const [shifOptions, setShiftOptions] = useState([])

    const { t, i18n } = useTranslation();
    const authContext = useContext(AuthContext);
    const menu = useRef(null);

    const isCustomerUser = authContext?.roleDTO?.name == customerAdmin
    const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm({ defaultValues });


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        setCurrentSearchData(data)
        searchOrders(data)
    }
    const getCustomerConfiguration = (customerId) => {
        customerService.getCustomerCateringConfiguration(customerId).then((data) => {
            if (data?.numberOfShifts) {
                let numberOptions = []
                for (let index = 0; index < data?.numberOfShifts; index++) {
                    numberOptions.push({
                        label: index + 1, value: index + 1
                    })
                }
                setShiftOptions(numberOptions)
            }

        })
    }
    const onCustomerChange = (e) => {
        if (e?.value?.length == 1) {
            getCustomerConfiguration(e.value)
            setCustomerUsers()
            let searchUserObject = { ...searchUserParams }
            searchUserObject.pageSize = 10000
            searchUserObject.customerId = e.value
            userService.search(searchUserObject).then(data => {
                setCustomerUsers(data?.items?.map(element => ({
                    label: element.name, value: element.id
                })))
            })
            setMyCustomersLocations([])
            if (e.value) {
                let customer = myCustomers.find(x => x.id == e.value)
                if (customer && customer?.locationDTOS?.length > 0) {
                    setMyCustomersLocations(customer.locationDTOS)
                }
            }
        } else {
            setMyCustomersLocations([])
            setCustomerUsers([])
            setShiftOptions([])
        }

    }
    const onCustomerLocationChange = (e) => {
        const { customerId } = getValues()
        setCustomerUsers()
        let searchUserObject = { ...searchUserParams }
        searchUserObject.pageSize = 10000
        searchUserObject.customerId = customerId
        searchUserObject.locationId = e.value
        userService.search(searchUserObject).then(data => {
            setCustomerUsers(data?.items?.map(element => ({
                label: element.name, value: element.id
            })))
        })

    }
    useEffect(() => {
        if (isCustomerUser) {
            setValue("customerId", authContext?.customerId)
            onCustomerChange({ value: authContext?.customerId })
            customerService.get(authContext?.customerId).then((data) => {
                if (data?.locationDTOS?.length > 0) {
                    setMyCustomersLocations(data.locationDTOS)
                }
            })
        } else {
            let searchObject = searchCustomerParams
            searchObject.pageSize = 1000
            searchObject.cateringId = authContext.cateringId
            customerService.search(searchObject).then(data => {
                setMyCustomers(data.items)

            })
        }

    }, [])

    let customersOptions = []
    if (myCustomers?.length > 0) {
        customersOptions = myCustomers.map(x => ({
            value: x.id, label: x.name
        }))
    }
    let customersLocationsOptions = []
    if (myCustomersLocations?.length > 0) {
        customersLocationsOptions = myCustomersLocations.map(x => ({
            value: x.id, label: x.locationName
        }))
    }
    // const isButtonDisabled = !customerId || !minDate || !maxDate || !type
    const isButtonDisabled = false
    const items = [
        {
            label: t('export'),
            items: [
                {
                    label: t('Excel'),
                    icon: 'pi pi-file-excel',
                    command: () => {
                        exportData(getValues(), 'excel')
                    }
                },
                {
                    label: t('PDF'),
                    icon: 'pi pi-file-pdf',
                    command: () => {
                        exportData(getValues(), 'pdf')

                    }
                }
            ]
        }
    ];
    return (
        <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
            <div className='grid'>
                <Menu model={items} popup ref={menu} id="popup_menu" />

                {/* <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="name" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('name')}
                </div> */}
                {!isCustomerUser && <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="customerId" className={classNames({ 'p-error': errors.customer })}>{t('customer')}*</label>
                    <Controller name="customerId" control={control} rules={{ required: t('customerRequired') }} render={({ field, fieldState }) => (
                        <MultiSelect id={field.name} {...field} onChange={(event) => {
                            field.onChange(event)
                            onCustomerChange(event)
                        }} options={customersOptions} className={classNames({ 'p-invalid': fieldState.invalid })} display='chip' />
                    )} />
                    {getFormErrorMessage('customer')}
                </div>}
                {customersLocationsOptions?.length > 0 && <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="locationId" className={classNames({ 'p-error': errors.customer })}>{t('location')}</label>
                    <Controller name="locationId" control={control} render={({ field, fieldState }) => (
                        <MultiSelect id={field.name} {...field} onChange={(event) => {
                            field.onChange(event)
                            onCustomerLocationChange(event)
                        }} options={customersLocationsOptions} className={classNames({ 'p-invalid': fieldState.invalid })} display="chip" />
                    )} />
                    {getFormErrorMessage('locationId')}
                </div>}
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="user" className={classNames({ 'p-error': errors.user })}>{t('user')}</label>
                    <Controller name="user" control={control} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} showClear filter {...field} options={customerUsers} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('user')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="shift" className={classNames({ 'p-error': errors.shift })}>{t('shift')}</label>
                    <Controller name="shift" control={control} render={({ field, fieldState }) => (
                        <MultiSelect display="chip" id={field.name} showClear {...field} options={shifOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('shift')}
                </div>
                {/* <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="category" className={classNames({ 'p-error': errors.category })}>{t('category')}*</label>
                    <Controller name="category" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('category')}
                </div> */}
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="minDate" className={classNames({ 'p-error': errors.minDate })}>{t('minDate')}*</label>
                    <Controller name="minDate" control={control} rules={{ required: t('minDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="dd/mm/yy" id={field.name} locale={i18n.language} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('minDate')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="maxDate" className={classNames({ 'p-error': errors.maxDate })}>{t('maxDate')}*</label>
                    <Controller name="maxDate" control={control} rules={{ required: t('maxDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="dd/mm/yy" id={field.name} locale={i18n.language} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('maxDate')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="status" >{t('status')}</label>
                    <Controller name="status" control={control} render={({ field, fieldState }) => (
                        <MultiSelect display="chip" showClear id={field.name} {...field} options={[
                            {
                                label: t('CREATED'), value: 'CREATED'
                            },
                            {
                                label: t('MISSING_ITEM'), value: 'MISSING_ITEM'
                            },
                            {
                                label: t('VERIFIED'), value: 'VERIFIED'
                            },

                        ]} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="type" className={classNames({ 'p-error': errors.customer })}>{t('typeOrder')}*</label>
                    <Controller name="type" control={control} rules={{ required: t('typeRequired') }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} options={[
                            {
                                label: t('singleOrder'), value: 'single'
                            },
                            {
                                label: t('amountOrder'), value: 'amount'
                            },
                            {
                                label: t('norm'), value: 'norm'
                            },
                        ]} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('type')}
                </div>
            </div>
            <div className='grid'>
                <div className='col-6 md:col-6 lg:col-2'>
                    <Button disabled={isButtonDisabled} label={t("search")} icon="pi pi-search" className="p-button mt-4 col-3" type="submit" />
                </div>
                <div className='col-6 md:col-6 lg:col-2'>
                    <Button disabled={isButtonDisabled} icon="pi pi-download" type='button' label={t('export')} className="p-button p-button-help mt-4" onClick={(event) => {
                        // setCurrentUserForEdit(rowData)
                        menu.current.toggle(event)
                    }} aria-controls="popup_menu" aria-haspopup />
                </div>
            </div>
        </form>
    );
};

export default FilterOrderForm;